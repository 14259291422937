
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonText,
  IonButton,
  IonRippleEffect,
  toastController,
} from "@ionic/vue";
import PageHeader from "@/components/partial/PageHeader.vue";
import { pencil, chevronForward } from "ionicons/icons";
import axios from "axios";
import { mapActions } from "vuex";

export default defineComponent({
  name: "Verify",

  components: {
    IonPage,
    IonContent,
    IonText,
    IonButton,
    IonRippleEffect,
    PageHeader,
  },

  setup() {
    return { pencil, chevronForward };
  },

  data() {
    return {
      urlImgKtp: null,
      urlImgSelfieKtp: null,
      loading: false,
      fileKtp: null,
      fileSelfieKtp: null,
    };
  },

  methods: {
    ...mapActions('user', {
      fetchUser: "fetchUser"
    }),
    async uploadBerkas() {
      this.loading = true;

      const formdata = new FormData();
      formdata.append('ktp', this.fileKtp)
      formdata.append('sktp', this.fileSelfieKtp)
      await axios
        .post("verification", formdata)
        .then(async ({ data }) => {
          this.loading = false;
          const toast = await toastController.create({
            message: data.message,
            duration: 2000,
          });
          this.fetchUser()
          toast.present();
          return this.$router.back(-1)
        })
        .catch(async ({ response }) => {
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2000,
          });
          return toast.present();
        });
    },
    onInputKtpChange(event: any) {
      const file = event.target.files[0];
      this.urlImgKtp = URL.createObjectURL(file);
      this.fileKtp = file;
    },

    onInputSelfieKtpChange(event: any) {
      const file = event.target.files[0];
      this.urlImgSelfieKtp = URL.createObjectURL(file);
      this.fileSelfieKtp = file;
    },
  },
});
